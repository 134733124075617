import { useEffect, useMemo, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyQueryNoCache } from 'Shared/Hooks/useApolloClient'
import { useStayDates } from 'Checkout/Hooks/useStayDates'
import { actionCampgroundSetData } from 'Checkout/Redux/Actions/Campground'
import { resetRvValidations, updateHasValidations, updateRvValidations } from './RvValidationsActions'
import rvValidationsReducer from './RvValidationsReducer'
import initialState from './RvValidationsInitialState'
import { validateRigSize } from 'Checkout/Utils/rvHelpers'
import rvValidationsQuery from './RvValidationsQuery'

const useRvValidations = () => {
  const campgroundSlug = useSelector(state => state.campground.slug)
  const dispatch = useDispatch()
  const { dayUse, rigLength, rigWidth, siteType } = useSelector(state => state.search)
  const [rvValidationState, setRvValidationsState] = useReducer(rvValidationsReducer, initialState)
  const [getValidations, { data, error, loading: loadingValidations }] = useLazyQueryNoCache(rvValidationsQuery)
  const { checkIn, checkOut, validStayDates } = useStayDates()

  useEffect(() => {
    if (validStayDates && campgroundSlug && siteType?.type) {
      const variables = { filters: { types: [siteType.type] }, filtersCamp: { dayUse }, from: checkIn,
                          slug: campgroundSlug, to: checkOut }
      setRvValidationsState(updateHasValidations(false))
      getValidations({ variables })
    }
  }, [dayUse, checkIn, checkOut, campgroundSlug, siteType])

  useEffect(() => {
    if ((!data && error) || error)
      setRvValidationsState(resetRvValidations())
    else if (data) {
      setRvValidationsState(updateRvValidations({ ...data.campground, checkIn, checkOut }))
      const { canUseNonRefundable, isInstantBooking, isRefundableFully } = data.campground
      dispatch(actionCampgroundSetData({ canUseNonRefundable, isInstantBooking, isRefundableFully }))
    }
   }, [data])

  const validRigSizes = useMemo(() => {
    const { maxLength, minLength, maxWidth, minWidth } = rvValidationState.validations

    if (rvValidationState.displayRigFilter)
      return validateRigSize({ rigLength, rigWidth, maxLength, minLength, maxWidth, minWidth })

    return true
  }, [rigLength, rigWidth, rvValidationState.displayRigFilter])

  return { rvValidationState, validRigSizes, loadingValidations }
}

export default useRvValidations
