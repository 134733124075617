const amenityIcons = {
  ac: { icon: 'snowflake', name: 'AC' },
  ada_accessible: { icon: 'wheelchair', name: 'ADA Accessible' },
  amps_100: { icon: 'plug', name: '100 Amps' },
  amps_20: { icon: 'plug', name: '20 Amps' },
  amps_30: { icon: 'plug', name: '30 Amps' },
  amps_50: { icon: 'plug', name: '50 Amps' },
  back_in: { icon: 'caravan', name: 'Back In' },
  bunk_bed: { icon: 'bed-bunk', name: 'Bunk Bed' },
  cable_hookups: { name: 'Cable Hookups' },
  cable: { name: 'Cable' },
  coffee_maker: { icon: 'coffee', name: 'Coffee Maker' },
  electrical_outlets: { name: 'Electrical Outlets' },
  electricity: { icon: 'bolt', name: 'Electricity' },
  fire_pit: { icon: 'campfire', name: 'Fire Pit' },
  full_bath: { icon: 'shower', name: 'Full Bath' },
  full_bathrooms: { icon: 'shower', name: 'Full Bathrooms' },
  full_bed: { name: 'Full Bed' },
  futon: { name: 'Futon' },
  grill_on_site: { name: 'Grill on Site' },
  half_bath: { icon: 'toilet', name: 'Half Bath' },
  hot_plate: { name: 'Hot Plate' },
  internet: { icon: 'wifi', name: 'Internet' },
  kitchenette: { name: 'Kitchenette' },
  linens: { name: 'Linens' },
  no_bath: { name: 'No Bath' },
  parking_on_site: { icon: 'parking', name: 'Parking on Site' },
  pets_allowed: { icon: 'paw', name: 'Pets Allowed' },
  picnic_table: { name: 'Picnic Table' },
  pillows: { name: 'Pillows' },
  pull_in: { name: 'Pull In' },
  pull_through: { icon: 'caravan', name: 'Pull Through' },
  queen_bed: { icon: 'bed-front', name: 'Queen Bed' },
  refrigerator: { name: 'Refrigerator' },
  rooms: { name: 'Rooms' },
  running_water: { icon: 'tint', name: 'Running Water' },
  sewer_hookups: { icon: 'tint', name: 'Sewer Hookups' },
  sewer: { icon: 'tint', name: 'Sewer' },
  tv: { icon: 'tv', name: 'TV' },
  twin_bed: { icon: 'bed-empty', name: 'Twin Bed' },
  utensils: { icon: 'utensils', name: 'Utensils' },
  water_hookups: { icon: 'tint', name: 'Water Hookups' },
  water: { icon: 'tint', name: 'Water' },
  wifi: { icon: 'wifi', name: 'Wifi' },
}

const amenityIcon = key => _getAmenityValue(key, 'icon')

const amenityName = key => _getAmenityValue(key, 'name')

const getAmenity = key => amenityIcons[key]
// private

function _getAmenityValue(key, value) {
  const amenity = amenityIcons[key]
  if (!amenity) return ''

  return amenity[value]
}

export { amenityIcon, amenityName, getAmenity }
