import FontAwesomeIcon from 'Checkout/Components/Shared/FontAwesomeLib'
import { getAmenity } from 'Checkout/Components/Shared/AmenityIcons'

const AmenityIcon = ({ amenity }) => {
  function renderIcon() {
    if ((amenity ?? '').trim() === '') return

    const { icon } = getAmenity(amenity) || { icon: 'star' }

    return <FontAwesomeIcon icon={icon} className="amenity-icon me-2" />
  }

  return <div className="amenity-icon">{renderIcon()}</div>
}

export default AmenityIcon
